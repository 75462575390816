.account-growing__wrapper{
  padding: 25px;
  border-radius: 5px;    
  background: #e9e8ff;
  border: .66px solid #a3a3ff;
}
.account-growing__info-wrapper{
  
}
.account-growing__growth-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1b2541;
  padding: 28px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-bottom: 12px;
  color: #fff;

}
.account-growing__growth-title{

}
.account-growing__growth-price{

}
.account-growing__email-wrapper{
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
  background: #fff;
  text-align: center;
  padding: 30px;
  // box-shadow: 0 1.9918096066px 84.6519241333px rgba(217,225,255,.59);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}
body[data-layout-mode=dark] .account-growing__email-wrapper{
  background: transparent;
}
.account-growing__email-title{

}
.account-growing__email-img{
  width: 100px;
}
.account-growing__email-button{
  display: flex;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
  background: #665bff;
  color: #fff !important;
  text-decoration: none !important;
  font-family: Rubik;
  font-weight: 500;
  line-height: normal;
  padding: 8px 40px;
  border-radius: 31px;
  cursor: pointer;
  color: #609fff;
  font-size: 13.071px;
  font-style: normal;
  font-weight: 400;
}
.account-growing__email-link{

}

.account-growing__email-text-wrapper{
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
}
.account-growing__email-text{

}
.account-growing__email-timer-wrapper{
  margin-top: 17px;
  border-radius: 4px;
  border: 1px solid #000;
  color: #000;
  font-family: Rubik;
  font-size: 16.806px;
  font-style: normal;
  font-weight: 600;
  line-height: 130.5%;
  text-align: center;
  padding: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

body[data-layout-mode=dark] .account-growing__email-timer-wrapper{
  border: 1px solid white;
  color: white;
}
.account-growing__email-timezone{

}