.account-details__wrapper{
  display:flex;
  justify-content: space-between;
  gap:30px;
}

.account-details__info-wrapper{
  display: flex;
  flex-direction: column;
  gap:10px
}
.account-details__info-img-wrapper{
  display: flex;
  // align-items: center;
  gap:20px;
}
.account-details__info-img{
  width:60px;
  height:60px;
  border-radius: 50%;
}
.account-details__info-details-wrapper{
  display: flex;
  flex-direction: column;
  // gap:10px
}
.account-details__info-details-title{
  color: #080663;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
  font-family: "system-ui";
}
.account-details__info-details-description{
  color: #080663;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 10px;
  font-family: "system-ui";
}

body[data-layout-mode=dark] .account-details__info-details-description{
  color: #d2d2d2;
}
.account-details__info-list{
  display: flex;
  flex-direction: column;
  gap:5px;
}
.account-details__info-list-item{
  display: flex;
  align-items: center;
  gap:10px;
  font-family: "system-ui";
}


.account-details__section-wrapper{
  display: flex;
  flex-direction: column;
  gap:20px;
}
.account-details__section-title{
  display: flex;
  align-items: center;
  color: #080663;
  font-size: 20px;
  font-weight: 700;
  font-family: "system-ui";
  gap:10px
}
.account-details__section-items-wrapper{
  display:grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px 25px;
}
.account-details__section-item-wrapper{
  display: flex;
  background-color: #ececec;
  align-items: center;
  justify-content: space-between;
  padding: 10px 19px;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
}


body[data-layout-mode=dark] .account-details__section-item-wrapper{
  background-color: transparent;
}
.account-details__section-item-text{
  color: #515151;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  font-family: "system-ui";
}

body[data-layout-mode=dark] .account-details__section-item-text{
  color: #d2d2d2;
}
.account-details__section-item-value{
  display: flex;
  align-items: center;
  gap:4px
}
.account-details__section-item-show-button{
  width:20px
}

.account-details__trading-wrapper{

}
.account-details__trading-items-wrapper{
  display: flex;
  flex-direction: column;
  gap:20px;
  margin-top: 20px;
}
.account-details__trading-item-text{
  font-size: 15px;
}
@media (max-width:1200px) {
  .account-details__wrapper {
    flex-direction: column;
  }
  .account-details__info-list{
    margin-left: initial;
  }
}
@media (max-width:500px) {
  
  .account-details__section-items-wrapper{
    grid-template-columns: 1fr;
  }
}