.account-stats__wrapper{

}
.account-details__card-title{
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 15px;
  margin-bottom: 25px;
  border-bottom: 1px solid #d2d2d2;
  color: #040039;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  font-family: "system-ui";
}
body[data-layout-mode=dark] .account-details__card-title path{
  fill: white
}
// .account-details__stats-items-wrapper{
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
//   gap:10px;
// }
.account-details__stats-item-wrapper{
  display: flex;
  flex-direction: column;
  gap:8px;
  padding: 10px 20px;
  background-color: #ececec;
}
.account-details__stats-item-icon-wrapper{
  padding: 5px;
  background-color: white;
  width: fit-content;
  border-radius: 50%;
}
body[data-layout-mode=dark] .account-details__stats-item-icon-wrapper{
  background-color: transparent;
}
.account-details__stats-item-text{

}
.account-details__stats-item-value{

}