.avFormInputWrapper{
    width: calc(100% - 38px);
}
.createPlanInputTitle{
    width: 170px;
}
.sectionSeperator{
    position: relative;
    text-align: center;
}
.sectionSeperator::before{
    content: "";
    width: 35%;
    height: 1px;
    background-color: #eee;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.sectionSeperator::after{
    content: "";
    width: 35%;
    height: 1px;
    background-color: #eee;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.createPlanInputGroup{
    flex-wrap: nowrap!important;
    align-items:center!important;
    margin-bottom:15px
}
.createPlanInputGroup div{
    margin-bottom: 0!important;
}
.createPlanInputValue{
    margin-left: 10px!important;
    width: 150px;
}