.account-stats__wrapper{

}
.account-details__card-title{
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 15px;
  margin-bottom: 25px;
  border-bottom: 1px solid #d2d2d2;
  color: #040039;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  font-family: "system-ui";
}
// body[data-layout-mode=dark] .account-details__card-title{
//   border-bottom: 0;
// }
.account-details__stats-items-wrapper{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap:10px;
}
.account-details__stats-item-wrapper{
  display: flex;
  flex-direction: column;
  gap:0px;
  padding: 10px 20px;
  background-color: #ececec;
}
body[data-layout-mode=dark] .account-details__stats-item-wrapper{
  border: 1px solid #d2d2d2;
  background-color: transparent;
}
.account-details__stats-item-icon-wrapper{
  padding: 5px;
  background-color: white;
  width: fit-content;
  border-radius: 50%;
}

.account-details__stats-item-text{
  color: #272642;
  font-size: 18.672px;
  font-weight: 700;
  font-family: "system-ui";
}
body[data-layout-mode=dark] .account-details__stats-item-text{
  color: #d2d2d2;
}
.account-details__stats-item-value{
  color: #272642;
  font-size: 15.723px;
  font-weight: 500;
  font-family: "system-ui";
}
body[data-layout-mode=dark] .account-details__stats-item-value{
  color: #d2d2d2;
}
@media (max-width: 500px) {
  .account-details__stats-items-wrapper{
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 1200px) {
  .account-details__stats-items-wrapper{
    grid-template-columns: repeat(2, 1fr);
  }
}