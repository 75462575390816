.account-stats__wrapper{

}
.account-details__card-title{
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 15px;
  margin-bottom: 25px;
  border-bottom: 1px solid #d2d2d2;
  color: #040039;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  font-family: "system-ui";
}
.account-trading__items-wrapper{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap:10px;
}
.account-trading__item-wrapper{
  display: flex;
  gap:8px;
  padding: 10px 20px;
  background-color: #ececec;
}

body[data-layout-mode=dark] .account-trading__item-wrapper{
  background-color: transparent;
  border: 1px solid #d2d2d2;
}
.account-trading__item-icon-wrapper{
  padding: 5px;
  background-color: white;
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
}
.account-trading__item-text{

}
.account-trading__item-value{

}
.account-trading__item-content-title{
  color: #040039;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  font-family: "system-ui";
}

.account-trading__item-status-wrapper{
  display: flex;
  align-items: center;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  line-height: 150%;
  padding: 6px 17px;
  border-radius: 18.14px;
  margin-left: auto;
  background: #d2cfff;
  height: fit-content;
}
.account-trading__item-status-color{
  background: #635bff;
  display: flex;
  width: 8.825px;
  height: 8.825px;
  border-radius: 50%;
  margin-right: 9px;
}

@media (max-width: 500px) {
  
  .account-trading__items-wrapper{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap:10px;
  }
}

@media (max-width: 1200px) {
  
  .account-trading__items-wrapper{
    grid-template-columns: repeat(1, 1fr);
  }
}