.challengs-top-bar-container{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.challengs-top-bar-item{
  position: relative;
  display: flex;
  align-items: center;
  gap:8px
}
.challengs-top-bar-item--bar::after{
  content: "";
  position: absolute;
  left: 110%;
  top: 50%;
  transform: translateY(-50%);
  width:140%;
  height:1px;
  background-color: rgba(0, 0, 0, 0.25);
}
.challengs-top-bar-item--bar::before{
  content: "";
  position: absolute;
  right: 110%;
  top: 50%;
  transform: translateY(-50%);
  width:140%;
  height:1px;
  background-color: rgba(0, 0, 0, 0.25);
}
.challengs-top-bar-number{
  width:32px;
  height:32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:50%;
  background-color: transparent;
  border:1px solid rgba(0, 0, 0, 0.25);
  color:rgba(0, 0, 0, 0.25);
  font-size: 16px;
}
.challengs-top-bar-item--active .challengs-top-bar-number{
  
  background-color: #FFC827;
  border-color:#FFC827;
  color:white;
  font-weight: bold;
}
.challengs-top-bar-text{
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
}
.challengs-top-bar-item--active .challengs-top-bar-text{
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
}


.challenge-plan-cards-wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap:30px;
}
.challenge-plan-card{
  display: flex;
  flex-direction: column;
}
.challenge-plan-card-title{
  margin-bottom: 15px;
}
.challenge-plan-card-description{
  margin-bottom: 15px;
}
.challenge-plan-card-link{

}
.challenge-plan-card-price{
  padding:20px;
  border-radius: 5px;
  margin-left: auto;    
  border-color: #e4eaee !important;
  background-color: #f4f4f4 !important;
  color: #414750 !important;
  box-shadow: none !important;
}
.challenge-plan-card-price:hover{
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.1),0 2px 2px 0 rgba(0,0,0,.1),0 1px 5px 1px rgba(0,0,0,.1)!important;
}
.challenge-plan-card-price--active{    
  background-color: #586cb1 !important;
  border-color: #586cb1 !important;
  color: #fff !important;
}
.btn-outline-custom{
  border-color: blue!important;

}